

const ProjectCardData = [
    // {
    //     // projectimage: "project4.png",
    //     // title: "My online portfolio in React ",
    //     // text: "I have utilized a variety of libraries, including Elfsight, Email JS, and Sweet alert.This website is hosted by Vercel and GitHub, and I got my domain name via IONOS.com. So if you want to learn it go check it.",
    //     // view: "https://www.vasuchopra.com/"
    // },
    {
        projectimage: "project1.png",
        title: "Ecommerce Website in Wordpress",
        text: "This is an ecommerce website for selling mens clothing products categorized by T-shirts, shirts and Blazers. This website is created in wordpress so if you want to learn it go check it",
        view: "http://mantrastore.great-site.net/"
    },
    {
        projectimage: "project2.png",
        title: "THE FOOD LAB, MEAL PLAN SELLING WEBSITE",
        text: "Utilizing MERN Stack technology, this site was created. It is a mobile-friendly website.This website was created using the Agile process .So if you want to learn it go check it.",
        view: "http://143.198.39.117/"
    },
    {
        projectimage: "project3.png",
        title: "G1 FITNESS, THE WEBSITE FOR THE GYM",
        text: "Customers of the GYM can purchase memberships and gain access to meal plans through this website, which was created specifically for them..HTML, CSS, and JavaScript were used in its creation.",
        view: "https://imchopra.github.io/Fitness/"
    },

];

export default ProjectCardData;